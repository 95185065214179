<template>
  <div class="disclaimer" :class="{ 'disclaimer--no-bg': noBackground }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Disclaimer',
  props: {
    noBackground: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.disclaimer {
  background-color: $gray-1;
  border-radius: 4px;
  font-size: 12px;
  margin: 24px 0;
  padding: 16px;

  &.disclaimer--no-bg {
    background-color: transparent;
  }

  @media (min-width: 992px) {
    text-align: center;
  }

  a {
    font-size: 12px;
  }
}
</style>
