<template>
  <div class="tips__header">
    <img src="./img/foto.png" alt="">
    <div class="tips__header__info">
      <h2 class="page__title tips__header__title">Contar com a 99Empresas é cuidar da sua empresa.</h2>
      <p class="page__desc tips__header__text">Com a nossa plataforma de gestão, você conta com recursos como verificação dos perfis dos motoristas, monitoramento em tempo real e suporte 24h.</p>
      <img src="./img/icone1.png" alt="" width="110">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style lang="scss" scoped>
.tips__header {
  background-color: $warning-2;
  margin-top: 61px;
  padding-bottom: 10px;
  position: relative;

  &:before {
    background: url(./img/ellipse.png) no-repeat center;
    background-size: cover;
    content: '';
    display: block;
    height: 202px;
    left: 45px;
    position: absolute;
    top: -15px;
    width: 138px;
    z-index: 0;
  }

  > img {
    position: relative;
    width: 180px;
    z-index: 1;
  }
}

.tips__header__info {
  color: $gray-7;
  left: 152px;
  position: absolute;
  top: 34px;
  z-index: 1;

  img {
    left: 0;
    position: absolute;
    top: 70px;
    width: 70px;
  }
}

.tips__header__title {
  margin-bottom: 10px;
}

.tips__header__text {
  position: relative;
  margin-bottom: 0;
  padding-left: 78px;
}
</style>

<style lang="scss">
.page--user-registration {

  .tips__header {

    .page__header__actions__text {
      color: darken($gray-7, 5);
    }
  }
}
</style>
