<template>
  <div>
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
      @submit.prevent="handleSubmit"
    >
      <label for="name">Seu nome</label>
      <v-text-field
        v-model="form.name"
        placeholder="Nome e sobrenome"
        id="name"
        autocomplete="off"
        outlined
        required
        v-validate="{ required: true, nameAndSurname: true }"
        :data-vv-name="'nome'"
        :error-messages="errors.collect('nome')"
      />

      <label for="companyName">Razão Social (Igual ao cartão CNPJ)</label>
      <v-text-field
        v-model="form.companyName"
        placeholder="O nome da empresa que representa"
        id="companyName"
        autocomplete="off"
        outlined
        required
        v-validate="{ required: true }"
        :data-vv-name="'empresa'"
        :error-messages="errors.collect('empresa')"
      />

      <label for="corporateEmail">Seu email corporativo</label>
      <div class="input__tooltip">
        <v-text-field
          v-model="form.corporateEmail"
          placeholder="ex.: voce@suaempresa.com"
          id="corporateEmail"
          autocomplete="off"
          outlined
          required
          v-validate="{ required: true, isValidEmail: true, coporateEmail: true }"
          :data-vv-name="'email'"
          :error-messages="errors.collect('email')"
        />
        <v-tooltip max-width="178" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" color="primary" class="rotate--180">
              mdi-alert-circle
            </v-icon>
          </template>
          <span>Digite seu email corporativo, emails gratuitos não são aceitos.</span>
        </v-tooltip>
      </div>

      <div class="input__disclaimer">
        <label for="cellPhone">Telefone celular com DDD</label>
        <v-text-field
          v-model="cellPhoneWithMask"
          placeholder="(••) • •••• ••••"
          id="cellPhone"
          autocomplete="off"
          v-mask="'(##) # #### ####'"
          outlined
          required
          v-validate="{ required: true, cellPhone: true }"
          :data-vv-name="'celular'"
          :error-messages="errors.collect('celular')"
          @input="handleClearCellPhoneMask"
        />
        <div class="input__disclaimer__text">O mesmo que você já utiliza no seu 99App</div>
      </div>

      <Disclaimer>
        Clicando em "Cadastrar empresa" você está concordando com nossos <a href="https://99app.com/legal/termos/empresas/?_ga=2.220915024.992378727.1550749962-1655554687.1541021017" title="Termos de uso" target="_blank">termos de uso</a> e com a possibilidade de entrarmos em contato pelos meios disponíveis, inclusive via WhatsApp.
      </Disclaimer>

      <FormFooter left>
        <v-btn
          type="submit"
          color="warning--gradient"
          :loading="isLoading"
          :disabled="isLoading"
          depressed
          rounded
          large
        >
          Cadastrar empresa
        </v-btn>
      </FormFooter>

      <VueRecaptcha
        ref="recaptcha"
        :sitekey="recaptchaKey"
        loadRecaptchaScript
        size="invisible"
        @verify="setRecaptchaToken"
        @expired="resetRecaptchaToken"
      />
    </v-form>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { STATUS_IS_LOADING, STATUS_SUCCESS, STATUS_FAILED } from 'utils/constants'
import { isObject } from 'utils/functions'
import hubspot from 'libs/hubspot'
import VueRecaptcha from 'vue-recaptcha'
import formValidatorMixin from 'mixins/formValidator'
import persistProspect from 'mixins/persistProspect'
import Disclaimer from 'components/Disclaimer'
import FormFooter from 'components/FormFooter'
import { mapActions } from 'vuex'

export default {
  name: 'Form',
  mixins: [formValidatorMixin, persistProspect],
  directives: { mask },
  components: {
    VueRecaptcha,
    Disclaimer,
    FormFooter
  },
  computed: {
    recaptchaKey () {
      return process.env.VUE_APP_RECAPTCHAKEY
    },

    urlParams () {
      // eslint-disable-next-line camelcase
      const { track_hutk = '', f = '' } = this.$route.query
      const emailDecoded = f ? JSON.parse(atob(f)).email : ''
      const email = /.+@.+\..+/.test(emailDecoded) ? emailDecoded : ''
      return { hutk: track_hutk, email }
    },

    isLoading () {
      return this.currentStatus === STATUS_IS_LOADING
    },

    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },

    isFailed () {
      return this.currentStatus === STATUS_FAILED
    }
  },
  data () {
    return {
      currentStatus: null,
      simpleProspectError: false,
      valid: true,
      cellPhoneWithMask: '',
      form: {
        name: '',
        companyName: '',
        corporateEmail: '',
        cellPhone: '',
        hutk: '',
        recaptchaToken: ''
      },
      recaptchaTokenRetries: 0
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'snackbar/show'
    }),

    isValidObject (val) {
      return isObject(val)
    },

    updateFormObject (obj) {
      this.form = {
        ...this.form,
        ...obj
      }
    },

    setRecaptchaToken (token) {
      this.updateFormObject({ recaptchaToken: token })
    },

    resetRecaptchaToken () {
      const $recaptcha = this.$refs.recaptcha
      $recaptcha && $recaptcha.reset()
    },

    executeRecaptchaToken () {
      const $recaptcha = this.$refs.recaptcha
      $recaptcha && $recaptcha.execute()
    },

    async initRecaptchaToken () {
      this.executeRecaptchaToken()
      await this.getRecaptchaToken()
    },

    async getRecaptchaToken () {
      const { recaptchaToken } = this.form
      this.recaptchaTokenRetries++

      return new Promise((resolve) => {
        if (!recaptchaToken) {
          setTimeout(() => resolve(this.initRecaptchaToken()), 2000)
        } else {
          return resolve()
        }
      })
    },

    getUrlParams () {
      const { email, hutk } = this.urlParams
      if (email) this.updateFormObject({ corporateEmail: email })
      if (hutk) this.updateFormObject({ hutk })
    },

    handleClearCellPhoneMask (val = '') {
      const cellPhone = val.replace(/\D/g, '')
      this.updateFormObject({ cellPhone })
    },

    redirectToNextStep () {
      this.$router.push({ name: 'CompanyRegistration' })
    },

    isInvalidRequestResponse (res) {
      return !this.isValidObject(res) || (this.isValidObject(res) && !res.valid)
    },

    renderSimpleProspectPayload () {
      const { corporateEmail, hutk, recaptchaToken } = this.form
      const hubspotUtk = hutk || hubspot.getCookie()
      const pageUrl = location.href.replace(/\?.+/, '')

      console.log('simple prospect', {
        email: corporateEmail,
        hubspotUtk,
        recaptchaToken,
        pageName: document.title,
        pageUrl
      })

      return {
        email: corporateEmail,
        hubspotUtk,
        recaptchaToken,
        pageName: document.title,
        pageUrl
      }
    },

    async createSimpleProspect () {
      try {
        await this.initRecaptchaToken()
        const payload = this.renderSimpleProspectPayload()
        const { data: res } = await this.$apiService.step1.createSimpleProspect(payload)

        if (this.isInvalidRequestResponse(res)) {
          throw new Error(res.reasons)
        }

        const id = res.content && res.content.id ? res.content.id : null

        this.updateFormObject({ id })
        this.saveProspect({
          currentStep: 1,
          data: { ...this.form }
        })

        return res
      } catch (error) {
        this.simpleProspectError = true
        return error
      }
    },

    createSimpleProspectAutoRequest () {
      if (this.urlParams.email) {
        setTimeout(() => this.createSimpleProspect(), 3000)
      }
    },

    renderUpdateProspectPayload () {
      const { id, hutk, name, corporateEmail, cellPhone, companyName, recaptchaToken } = this.form
      const hubspotUtk = hutk || hubspot.getCookie()

      return {
        id,
        hubspotUtk,
        responsable: {
          name: name,
          email: corporateEmail,
          phone: cellPhone
        },
        company: {
          name: companyName
        },
        recaptchaToken
      }
    },

    async updateProspect () {
      try {
        const payload = this.renderUpdateProspectPayload()
        const { data: res } = await this.$apiService.step1.updateProspect(payload)

        if (this.isInvalidRequestResponse(res)) {
          throw new Error(res.reasons)
        }

        this.saveProspect({
          currentStep: 2,
          data: { ...this.form }
        })

        return res
      } catch (error) {
        return error
      }
    },

    requestDelay (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },

    async handleSubmit () {
      const isValid = await this.$validator.validateAll()
      const errorPayload = {
        valid: false,
        reasons: ['Ocorreu um erro ao enviar as informações, tente novamente mais tarde.'],
        message: 'Ocorreu um erro ao enviar as informações, tente novamente mais tarde.'
      }

      try {
        if (isValid) {
          this.currentStatus = STATUS_IS_LOADING

          if (!this.urlParams.email || this.simpleProspectError) {
            const createProspect = await this.createSimpleProspect()
            const updateProspect = await this.updateProspect()

            if (!createProspect.valid || !updateProspect.valid) {
              throw new Error(errorPayload)
            }
          } else {
            const updateProspect = await this.updateProspect()

            if (!updateProspect.valid) {
              throw new Error(errorPayload)
            }
          }

          this.currentStatus = STATUS_SUCCESS
          this.redirectToNextStep()
        }
      } catch (error) {
        this.currentStatus = STATUS_FAILED
        this.showAlert({
          type: 'error',
          message: errorPayload.message,
          timeout: 8000
        })

        return error
      }
    }
  },
  mounted () {
    this.getUrlParams()
    window.addEventListener('load', this.createSimpleProspectAutoRequest)
  },
  beforeDestroy () {
    window.removeEventListener('load', this.createSimpleProspectAutoRequest)
  }
}
</script>
