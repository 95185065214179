'use strict'

import Cookie from 'utils/Cookie'

const hubspot = {
  PORTAL_ID: 2206199,
  FORM_GUID: '3e63d0b1-7ec7-4239-b938-9c5ba36b05fd',
  hutk: null,
  getCookie () {
    this.hutk = Cookie.get('hubspotutk')
    return this.hutk
  }
}

export default hubspot
