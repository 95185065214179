<template>
  <section class="page page--user-registration" data-js="page">
    <div class="page__left">
      <div class="page__content page__content--left">
        <PageHeader :actions="!hasDesktopResolution" />

        <div class="page__body">
          <PageTitles>
            <template v-slot:title>
              A plataforma que pode mudar a mobilidade corporativa da sua empresa.
            </template>

            <template v-slot:desc>
              Este canal é destinado ao cadastro de novas empresas, caso seja um colaborador e gostaria de utilizar nossos serviços entre em contato com seu gestor.
            </template>
          </PageTitles>

          <Form />
        </div>
      </div>
    </div>

    <div v-if="hasDesktopResolution" class="page__right">
      <Tips />
    </div>
  </section>
</template>

<script>
import mediaQueries from 'mixins/mediaQueries'
import PageHeader from 'components/pageHeader'
import PageTitles from 'components/PageTitles'
import Form from 'components/userRegistration/Form'
import Tips from 'components/userRegistration/tips'

export default {
  name: 'UserRegistration',
  mixins: [mediaQueries],
  metaInfo () {
    return {
      titleTemplate: this.routeTitle + ' | %s'
    }
  },
  components: {
    PageHeader,
    PageTitles,
    Form,
    Tips
  },
  computed: {
    routeTitle () {
      return this.$route.meta.title
    },

    hasDesktopResolution () {
      return this.isDesktop || this.isLargeDesktop || this.isExtraLargeDesktop
    },

    routeQueries () {
      return this.$route.query
    }
  },
  methods: {
    sendGTMDataLayer () {
      this.$GTMDataLayer.send({
        event: 'cadastro-step1',
        'step-form': '1-dados-do-cadastro'
      })
    }
  },
  mounted () {
    // this.$apiService.greyTest() // 注册白名单测试，用于测试接口灰度是否生效
    this.sendGTMDataLayer()
  }
}
</script>

<style lang="scss" scoped>
.page__content--left {

  @media (min-width: 1200px) {
    padding-right: 98px;
  }
}

.page__left {
  background-color: $white;
}

.page__right {
  background-color: $gray-1;
  border-left: 1px solid $gray-3;
  flex-direction: column;
}
</style>
