<template>
  <div class="tips">
    <div class="tips__header">
      <div class="page__content page__content--right">
        <PageHeaderActions />
        <TipsHeader />
      </div>
    </div>

    <div class="tips__body">
      <div class="page__content page__content--right">
        <TipsBody />
      </div>
    </div>
  </div>
</template>

<script>
import PageHeaderActions from 'components/pageHeader/PageHeaderActions'
import TipsHeader from './Header'
import TipsBody from './Body'

export default {
  name: 'Tips',
  components: {
    PageHeaderActions,
    TipsHeader,
    TipsBody
  }
}
</script>

<style lang="scss" scoped>
.tips__header {
  background-color: #fed831;
}

.tips__body {
  background-color: $gray-1;
}
</style>

<style lang="scss">
.page--user-registration {

  .tips__header {

    .page__header__actions__text {
      color: $gray-7;
    }
  }
}
</style>
