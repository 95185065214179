<template>
  <div class="tips__body">
    <ul class="tips__list">
      <li>
        <h2 class="page__title">Pensar nos colaboradores é cuidar da sua empresa.</h2>
        <div class="tips__info">
          <span>Segurança sempre em foco! Oferecemos mais de 50 recursos de segurança para motoristas e passageiros, antes, durante e após cada corrida.</span>
          <img src="./img/icone2.png" alt="">
        </div>
      </li>

      <li>
        <h2 class="page__title">Otimizar os recursos é cuidar da sua empresa.</h2>
        <div class="tips__info">
          <span>Otimize os custos com a plataforma mais acessível do mercado! Elimine gastos da frota própria e otimize seu custo por KM com a 99Empresas.</span>
          <img src="./img/icone3.png" alt="">
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Body'
}
</script>

<style lang="scss" scoped>
.tips__body {
  background-color: $gray-1;
}

.tips__list {
  padding: 0;
  list-style-type: none;

  li {

    & + li {
      margin-top: 30px;
    }

    h2 {
      margin-bottom: 16px;
    }

    .tips__info {
      padding-left: 80px;
      position: relative;

      span {
        display: block;
        margin-bottom: 8px;
      }

      img {
        height: 55px;
        left: 0;
        position: absolute;
        top: 5px;
      }
    }
  }
}
</style>
